import React from 'react';
import Icon from '@mdi/react';
import { mdiOpenInNew, mdiGitlab, mdiLinkedin, mdiTwitter, mdiNpm , mdiInstagram, mdiGithub, mdiMastodon   } from '@mdi/js';

function Links() {
  const links = [
    { href: "https://jacobhaap.com/", icon: mdiOpenInNew, text: "jacobhaap.com", class: "social-link-1" },
    { href: "https://gitlab.com/jacobhaap", icon: mdiGitlab, text: "GitLab", class: "social-link-2" },
    { href: "https://www.linkedin.com/in/jacobhaap/", icon: mdiLinkedin, text: "LinkedIn", class: "social-link-1" },
    { href: "https://twitter.com/jacobhaap", icon: mdiTwitter, text: "Twitter (X)", class: "social-link-2" },
    { href: "https://www.npmjs.com/~jacobhaap", icon: mdiNpm, text: "NPM", class: "social-link-1" },
    { href: "https://instagram.com/jacobhaap", icon: mdiInstagram, text: "Instagram", class: "social-link-2" },
    { href: "https://github.com/jacobhaap", icon: mdiGithub, text: "GitHub", class: "social-link-1" },
    { href: "https://mastodon.social/@iacobus", icon: mdiMastodon, text: "Mastodon", class: "social-link-2" }
  ];

  return (
    <ul>
      {links.map((link, index) => (
        <li key={index}>
          <a href={link.href} target="_blank" rel="noopener noreferrer" className={link.class}>
            {link.icon ? (
              <Icon path={link.icon} size={1} className="social-icon" />
            ) : (
              <img src={link.imgSrc} className="social-icon" alt={link.text} />
            )}
            {link.text}
          </a>
        </li>
      ))}
    </ul>
  );
}

export default Links;
